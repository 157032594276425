import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import upBlackarrow from '../assets/images/up-blackarrow.svg';
import rightArrow from '../assets/images/blackright-arrow.svg';
import config from '../config';

export default function LocationSlider(props) {
  // const startTime = new Date(props.startTime);
  // const endTime = new Date(props.endTime);
  const currentDate = new Date();

  // const start = startTime.getHours();
  // const end = endTime.getHours();
  const start = props.startTime;
  const end = props.endTime;
console.log('parseInt(start)',parseInt(start))
  const s_s_startTime = props.s_s_startTime;
  const s_s_endTime = props.s_s_endTime;

  const current = currentDate.getHours();
console.log('current',current)
  var locationStatus = '';

  if (parseInt(start) <= current && current < parseInt(end)) {
    locationStatus = 'open now';
  }

  if (parseInt(start) <= current && current < parseInt(end)) {
    locationStatus = 'open now';
  } else {
    locationStatus = 'closed';
  }

  if (current === end - 1) {
    locationStatus = 'closing soon';
  }

  const openMenuModal = (index) => {
    props.setShowModal(true);
    if (props.key === index) {
      props.setModalContent({
        title: props.title,
        image: props.imgsrc,
        status: locationStatus,
        time: `M-S: ${start} - ${end}${parseInt(end) > 12 ? "pm" : "am"}`,
        // time1: `S+S: ${s_s_startTime % 12} - ${s_s_endTime % 12}${s_s_endTime > 12 ? "pm" : "am"}`,
        menuId: props.menuId,
        moreLink: props.moreLink
      });
    }
  }

  const formatTime = (time) => {
    if (!time) return ""; // Handle undefined or missing values
  
    const [hour, minute] = time.split(":").map(Number);
  
    // Check if hour and minute are valid numbers
    if (isNaN(hour) || isNaN(minute)) {
      return ""; // Return empty string if the time is invalid
    }
  
    const period = hour >= 12 ? "PM" : "AM"; // Determine AM/PM
    const formattedHour = hour % 12 || 12; // Convert 0 to 12 for midnight
    return `${formattedHour}:${minute.toString().padStart(2, "0")} ${period}`;
  };
  
  return (
    <>
      <div className='location-community-slider'>
        <div className="location-community-slider-items">
          <img src={props.imgsrc} alt="menu" />
          <div className="location-community-slider-items-desc">
            <h4>{props.title}<img src={rightArrow} alt="arrow-icon" /></h4>
            <div className="status-sec">
              <span className={`menu-status ${(locationStatus === 'closed' || locationStatus === 'closing soon') ? 'closing-soon' : 'open'}`}>
                {locationStatus}
              </span>
              <span className="menu-time">
                {`${formatTime(start)} - ${formatTime(end)}`}
              </span>
            </div>
            <div className="location-community-slider-items-desc-botm">
              <span onClick={() => openMenuModal(props.key)}>MENU</span>
              <NavLink className="more-icon" exact to={props.moreLink}>MORE<img src={upBlackarrow} alt="arrow-icon" /></NavLink>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

const LocationMenu = (props) => {
  // const startTime = new Date(props.startTime);
  // const endTime = new Date(props.endTime);
  const currentDate = new Date();

  // const start = startTime.getHours();
  // const end = endTime.getHours();
  const start = props.startTime;
  const end = props.endTime;

  // const endSoon = parseInt(props.endTime - 1);

  const s_s_startTime = parseInt(props.s_s_startTime);
  const s_s_endTime = parseInt(props.s_s_endTime);

  const current = currentDate.getHours();

  var locationStatus = '';

  if (parseInt(start) <= current && current < parseInt(end)) {
    locationStatus = 'open now';
  }

  if (parseInt(start) <= current && current < parseInt(end)) {
    locationStatus = 'open now';
  } else {
    locationStatus = 'closed';
  }

  if (current === parseInt(end) - 1) {
    locationStatus = 'closing soon';
  }

  const openMenuModal = (index) => {
    props.setShowModal(true);
    if (props.key === index) {
      props.setModalContent({
        title: props.title,
        image: props.imgsrc,
        status: locationStatus,
        time: `M-S: ${start} - ${end}${parseInt(end) > 12 ? "pm" : "am"}`,
        // time1: `S+S: ${s_s_s tartTime % 12} - ${s_s_endTime % 12}${s_s_endTime > 12 ? "pm" : "am"}`,
        menuId: props.menuId,
        moreLink: props.moreLink
      });
    }
  }

  return (
    <>
      <div className='location-community-slider'>
        <div className="location-community-slider-items">
          {!props.hideImage && (<img src={props.imgsrc} alt="menu" className='mobile-view' />)}
          <div className="location-community-slider-items-desc">

            <h4>{props.title}</h4>
            <div className='d-flex justify-content-between align-items-center'>
              <div className="status-sec">
                <span className={`menu-status ${(locationStatus === 'closed' || locationStatus === 'closing soon') ? 'closing-soon' : 'open'}`}>
                  {locationStatus}
                </span>

                {!props.hideImage ?
                  <span className='menu-time'>
                    {/* <span>{`M-S: ${start % 12 === 0 ? 12 : start % 12}-`}{`${end % 12}${end > 12 ? "pm" : "am"}`}</span> */}

                    <span>{`M-S: ${(String(start).split(":")[0] % 12) === 0 ? 12 : String(start).split(":")[0] % 12 }${(String(start).split(":")[1] !== "00" ? ":" + String(start).split(":")[1] : "")}`} - {`${(String(end).split(":")[0] % 12 === 0 ? 12 : String(end).split(":")[0] % 12)}${(String(end).split(":")[1] !== "00" ? ":" + String(end).split(":")[1] : "")}${String(end).split(":")[0] > 12 ? "pm" : "am"}`}</span>

                    {/* <span>{`S+S: ${s_s_startTime % 12}-`}{`${s_s_endTime % 12}${s_s_endTime > 12 ? "pm" : "am"}`}</span> */}
                  </span>
                  : (
                    <span className='menu-time'>{`${start % 12}${start > 12 ? "pm" : "am"}`}
                      <span>-</span>
                      <span>{`${end % 12}${end > 12 ? "pm" : "am"}`}</span>
                    </span>
                  )}

              </div>
              <div className="location-community-slider-items-desc-botm">
                <span onClick={() => openMenuModal(props.key)}>MENU {!props.hideImage && <img src={upBlackarrow} alt="arrow-icon" className='mobile-view' />}</span>
                <NavLink className="more-icon" exact to={props.moreLink}>MORE<img src={upBlackarrow} alt="arrow-icon" /></NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export { LocationMenu };