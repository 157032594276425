
import './App.css';
import './assets/css/modal.css';
import './assets/css/custom.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Header/navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Partnerwithus from './pages/Partner-with-us';
import SingleLocation from './pages/Single-Location';
import Locations from './pages/Locations';
import Gallery from './pages/Gallery';
import React, { useEffect, useState } from 'react';

import ProtectedRoute from './components/admincomponents/ProtectedRoutes';
import Dashboard from './pages/admin/Dashboard';
import AdminLogin from './components/admincomponents/AdminLogin';
import AddLocation from './pages/admin/AddLocation';
import UpdateLocation from './pages/admin/UpdateLocation';
import AdminLocations from './pages/admin/AdminLocations';
import ViewLocation from './pages/admin/ViewLocation';

// menu
import AddNewMenu from './pages/admin/AddNewMenu';
import ViewMenu from './pages/admin/ViewMenu';
import UpdateMenu from './pages/admin/UpdateMenu';

// events
import AdminEvents from './pages/admin/AdminEvents';
import ViewEvent from './pages/admin/ViewEvent';
import UpdateEvent from './pages/admin/UpdateEvent';
import AddEvent from './pages/admin/AddEvent';

import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isAdminRoute = window.location.pathname.startsWith('/admin');

  useEffect(() => {
    const body = document.querySelector("body");
    if(isAdminRoute){
      body.classList.add("adminPanel");
    }else{
        body.classList.remove("adminPanel");
        import('./assets/css/style.css');
    }

    const token = localStorage.getItem('token');
    if (token) {
        setIsAuthenticated(true);
    }
    setIsLoading(false); // Stop loading after checking token
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
  };

  if (isLoading) {
    // Render nothing or a loading spinner until authentication is confirmed
    return <div>Loading...</div>;
  }

  return (
    <>
    <PrimeReactProvider>
    <Navbar />
      <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/partner-with-us' element={<Partnerwithus />}></Route>  
          <Route path='/single-location/:id' element={<SingleLocation />}></Route>
          <Route path='/locations' element={<Locations />}></Route>
          <Route path='/gallery' element={<Gallery />}></Route>

          <Route
              path="/admin/dashboard"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <Dashboard onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />

          <Route 
              path="/admin/login" 
              element={
                  isAuthenticated ? (
                      <Navigate to="/admin/dashboard" />
                  ) : (
                      <AdminLogin onLogin={handleLogin} />
                  )
              } 
          />

          <Route
              path="/admin/locations"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <AdminLocations onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />

          <Route
              path="/admin/add-location"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <AddLocation onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />
          <Route
                path="/admin/location/:id"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <ViewLocation onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />
          
          <Route
                path="/admin/update-location/:id"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <UpdateLocation onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />

          <Route
              path="/admin/add-menu-item/:id"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <AddNewMenu onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />
          
          <Route
                path="/admin/menu/:id"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <ViewMenu onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />
          
          <Route
                path="/admin/update-menu/:id"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <UpdateMenu onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />

          <Route
              path="/admin/events"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <AdminEvents onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />

          <Route
              path="/admin/add-event"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <AddEvent onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />
          <Route
                path="/admin/event/:id"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <ViewEvent onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />
          
          <Route
                path="/admin/update-event/:id"
              element={
                  <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <UpdateEvent onLogout={handleLogout} />
                  </ProtectedRoute>
              }
          />
        </Routes>
    <Footer />
    </PrimeReactProvider>
    </>
  );
}

export default App;
