import React from "react";
import { NavLink } from 'react-router-dom';
import logourl from "../assets/images/LittleCove.svg";
import upWhiteArrow from '../assets/images/upWhiteArrow.svg';

function footer(){
    return(
        <>
        <div className="main_footer"> 
            <div className="container"> 
                <div className="main_footer_inner d-flex text-white">
                    <div className="footer_left_column"> 
                        <NavLink to="/" className="header-logo">
                            <img src={logourl} alt="header-logo" />
                        </NavLink>
                    </div>
                    <div className="footer_right_column">
                        <div className="footer_right_column_inner d-flex">
                            <div className="footer_menu_column">
                                <h4>links</h4>
                                <ul>
                                    <li>
                                        <NavLink to="/locations">Locations</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about">About Us</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/">Contact Us</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer_menu_column">
                                <h4>connect</h4>
                                <ul>
                                    <li>
                                        <NavLink to="/">Tiktok <img src={upWhiteArrow} alt="arrow-icon"/></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/">Instagram <img src={upWhiteArrow} alt="arrow-icon"/></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>                    
                    </div>
                </div>                
            </div>             
        </div>        
        </>
    );
}
export default footer;