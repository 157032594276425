import React, { useState } from 'react';
import galery1 from '../assets/images/main-gallery-1.jpg';
import galery2 from '../assets/images/main-gallery-2.jpg';
import galery3 from '../assets/images/main-gallery-3.jpg';
import galery4 from '../assets/images/main-gallery-4.jpg';
import galery5 from '../assets/images/main-gallery-5.jpg';
import galery6 from '../assets/images/main-gallery-6.jpg';
import galery7 from '../assets/images/main-gallery-7.jpg';
import galery8 from '../assets/images/main-gallery-8.jpg';
import galery9 from '../assets/images/main-gallery-9.jpg';
import galery10 from '../assets/images/main-gallery-10.jpg';
import galery11 from '../assets/images/main-gallery-11.jpg';
import upBlackarrow from '../assets/images/up-blackarrow.svg';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function Gallery() {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isSwiping, setIsSwiping] = useState(false);

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        autoplay: true,
        beforeChange: () => setIsSwiping(true), // Detect swipe start
        afterChange: () => setIsSwiping(false), // Reset swipe status
        responsive: [
            { breakpoint: 1900, settings: { slidesToShow: 3, slidesToScroll: 1 } },
            { breakpoint: 1500, settings: { slidesToShow: 3.5, slidesToScroll: 1, initialSlide: 0 } },
            { breakpoint: 1180, settings: { slidesToShow: 3, slidesToScroll: 1, initialSlide: 0 } },
            { breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1, initialSlide: 0 } },
            { breakpoint: 767, settings: { slidesToShow: 2.5, slidesToScroll: 1, initialSlide: 0 } },
            { breakpoint: 480, settings: { slidesToShow: 1.5, slidesToScroll: 1, initialSlide: 0, autoplay: false } },
        ]
    };

    const galleryItemsDesktop = [
        { id: 1, images: [{ src: galery1, alt: "gallery" }] },
        { id: 2, images: [{ src: galery2, alt: "gallery" }, { src: galery3, alt: "gallery" }] },
        { id: 3, images: [{ src: galery4, alt: "gallery" }] },
        { id: 4, images: [{ src: galery5, alt: "gallery" }, { src: galery6, alt: "gallery" }] },
        { id: 5, images: [{ src: galery7, alt: "gallery" }] },
        { id: 6, images: [{ src: galery8, alt: "gallery" }, { src: galery9, alt: "gallery" }] },
        { id: 6, images: [{ src: galery10, alt: "gallery" }, { src: galery11, alt: "gallery" }] },
    ];

    const allImages = galleryItemsDesktop.flatMap(item => item.images.map(img => img.src));

    const handleImageClick = (index) => {
        if (!isSwiping) { // Only open lightbox if not swiping
            setPhotoIndex(index);
            setIsOpen(true);
        }
    };

    return (
        <>
            <div className='gallery-banner page-banner position-relative text-black'>
                <div className="container">
                    <div className='page-banner-inner d-flex justify-content-between align-items-end'>
                        <div className='page-banner-inner-left'>
                            <div className="our-story-top-sec d-flex ">
                                <h6>GALLERY</h6>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gallery-slider position-relative desktop-view">
                <Slider {...settings}>
                    {galleryItemsDesktop.map((item, itemIndex) => (
                        <div key={item.id} className="gallery-slider-items">
                            {item.images.map((img, imgIndex) => (
                                <img
                                    key={imgIndex}
                                    src={img.src}
                                    alt={img.alt}
                                    style={{ height: img.height || 'auto', cursor: 'pointer' }}
                                    onClick={() => handleImageClick(itemIndex + imgIndex)}
                                />
                            ))}
                        </div>
                    ))}
                </Slider>
                <Link
                    to="/locations"
                    className="text-white banner-btn desktop-view position-absolute">
                    VIEW LOCATIONS
                    <img src={upBlackarrow} alt="arrow-icon" />
                </Link>
                {isOpen && (
                    <Lightbox
                        mainSrc={allImages[photoIndex]}
                        nextSrc={allImages[(photoIndex + 1) % allImages.length]}
                        prevSrc={allImages[(photoIndex + allImages.length - 1) % allImages.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + allImages.length - 1) % allImages.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % allImages.length)
                        }
                    />
                )}
            </div>
        </>
    );
}
