import React from "react";
import { NavLink } from 'react-router-dom';
import BannerImage from '../assets/images/home=meet-banner.jpg';
import MobileBannerImage from '../assets/images/home=meet-banner-mobile.jpg';
import upWhiteArrow from '../assets/images/upWhiteArrow.svg';

function meetUs(){
    return(
        <>
        <div className="meet_us position-relative">    
        <picture>
        <source media="(max-width: 767px)" srcSet={MobileBannerImage} />
        <img 
            src={BannerImage} 
            alt="Banner Image" 
            className="position-absolute h-100 w-100 s-0 t-0 meetbgimg" 
        />
        </picture>

                     
            <div className="meetus_banner_content position-relative plr-100 text-center fw-200">  
                <div className="container text-white">
                    <div className="meetus_banner_content_inr">
                        <h2 className="m-0">meet us</h2>
                        <p>We make great coffee and exceptional food within beautifully designed spaces tailored to each location and community. Our approach is simple. Do it with love.</p>
                        <NavLink className="morebtn" exact to="">MORE<img src={upWhiteArrow} alt="arrow-icon"/></NavLink>
                    </div>
                </div>
            </div>             
        </div>        
        </>
    );
}
export default meetUs;